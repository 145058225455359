import React from 'react';
import './AboutUs.css'; // Archivo de estilos para el componente
import artwork from '../assets/images/artwork.jpg'; // Ruta de la imagen

const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="about-us-container" id="about">
        <div className="about-us-content">
          <div className="about-us-text-container">
            <h2 className="about-us-title">Arte con Impacto</h2>
            <p className="about-us-text">
              Somos una propuesta innovadora que fusiona arte, sostenibilidad, tecnología e impacto social.
              Nuestra misión es promover artistas y sus obras, generando un impacto positivo: económico, social y ambiental,
              apostando al arte como una herramienta para la transformación.
            </p>
          </div>
          {/* Imagen de la obra de arte */}
          <div className="about-us-image-container">
            <img src={artwork} alt="Obra de arte" className="about-us-image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
