import React from "react";
import { Link } from "react-scroll"; // Importa Link de react-scroll
import "./Hero.css";
import logo from "../assets/logo-text.png";

const Hero = () => {
  return (
    <section className="hero" id="home">
      <div className="hero-content">
      <img src={logo} alt="Logo" className="hero-logo" />
        <h1 className="subtitle">
          TRANSFORMAMOS LA EXPERIENCIA DEL ARTE… ¡CON IMPACTO!
        </h1>
        
        {/* Agregamos las 3 palabras con sus respectivos estilos */}
        <div className="hero-words">
          <span className="word descubrir">DESCUBRIR</span>
          <span className="word">-</span>
          <span className="word conectar">CONECTAR</span>
          <span className="word">-</span>
          <span className="word explorar">EXPLORAR</span>
        </div>

        {/* Link de react-scroll para desplazarse suavemente a Problem.js */}
        <Link to="about" smooth={true} duration={1000} className="arrow-down">
          &#x2193;
        </Link>
      </div>
    </section>
  );
};

export default Hero;
