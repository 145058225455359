import React from 'react';
import { Link } from 'react-scroll'; // Importa Link de react-scroll
import './Problem.css'; 
import artistaImg from '../assets/images/artista.jpg'; 
import publicoImg from '../assets/images/publico.png'; 

const Problem = () => {
  return (
    <section className="problem-section"> {/* ID agregado aquí */}
      <h2 className="section-title">PROBLEMA</h2>
      <p className="problem-description">El Dilema del Arte: Acceso Limitado, Falta de Visibilidad y Experiencia Fragmentada</p>
      <div className="problem-content">
        <div className="problem-artist">
          <img src={artistaImg} alt="Artista" className="problem-image" />
          <h3>Artista</h3>
          <ul>
            <li>¿Cómo hago conocer mi obra?</li>
            <li>¿Cómo hago para que mi carrera crezca?</li>
            <li>¿Cómo genero mayores ingresos con mi arte?</li>
            <li>¿Dónde me reconocen y me ayudan a vender mi obra?</li>
          </ul>
        </div>
        <div className="problem-public" id="problem">
          <img src={publicoImg} alt="Público" className="problem-image" />
          <h3>Público</h3>
          <ul>
            <li>¿Cómo consigo un lindo regalo que no me cueste tanto?</li>
            <li>"El arte no es para mí, no lo entiendo"</li>
            <li>"Comprar arte original es para ricos"</li>
            <li>"Quiero comprar arte de manera fácil y confiable"</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Problem;
