import React from 'react';
import './Contact.css'; // Estilos personalizados
import logo from '../assets/logo-text.png';

const Contact = () => {
  return (
    <section className="contact-section">
      {/* Fondo blanco para la primera parte */}
      <div className="white-background">
        <h2 className="contact-header" id= "contact">¡Hagamos que el arte tenga Impacto, Juntos!</h2>
      </div>

      {/* Fondo de color para la segunda parte */}
      <div className="color-background">
        <div className="contact-info">
          {/* Logo y texto */}
          <div className="contact-logo">
            <img src={logo} alt="Viewseum Logo" />
          </div>

          {/* Enlaces e información */}
          <div className="contact-details">
            <p><i className="fas fa-globe"></i> <a href="https://viewseum.org" target="_blank" rel="noopener noreferrer">https://viewseum.org</a></p>
            <p><i className="fas fa-envelope"></i> <a href="mailto:juanpaulogalvis@gmail.com">juanpaulogalvis@gmail.com</a></p>
            <p><i className="fas fa-phone"></i> (54911) 5378 9317</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
