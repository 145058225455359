import React from 'react';
import './Solution.css';
import imagen1 from '../assets/images/Imagen1.png';
import imagen2 from '../assets/images/Imagen2.png';
import imagen3 from '../assets/images/Imagen3.png';
import imagen4 from '../assets/images/Imagen4.png';
import logo from '../assets/logo-text.png'; // Asegúrate de ajustar la ruta del logo

const Solution = () => {
  return (
    <section className="solution-section">
      <div className="solution-container">
      <h2 className="solution-title" id="solution">SOLUCIÓN</h2>

        {/* Contenedor de las imágenes */}
        <div className="images-container">
          <img src={imagen1} alt="Solución Viewseum 1" className="solution-image large-image" />
          <img src={imagen2} alt="Solución Viewseum 2" className="solution-image small-image" />
          <img src={imagen3} alt="Solución Viewseum 3" className="solution-image large-image" />
          <img src={imagen4} alt="Solución Viewseum 4" className="solution-image small-image" />
        </div>

        {/* Contenedor de texto y logo */}
        <div className="solution-footer">
          <div className="solution-text">
            <h3>EXPERIENCIA ÚNICA EN EL ARTE</h3>
            <p>
              Accesibilidad, información sobre arte y exposiciones, comercio de arte confiable y eficiente, 
              inmersión, mejora de la experiencia, educación y entretenimiento en una sola plataforma.
            </p>
          </div>

          {/* Logo alineado a la derecha */}
          <div className="solution-logo">
            <img src={logo} alt="Viewseum Logo" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution;
