import React from 'react';
import './PropuestaValor.css'; 
import artistaImg from '../assets/images/artista-pincel.jpg'; 
import publicoImg from '../assets/images/artista-notebook.png';
import obraImg from '../assets/images/obra.png';
import diagramaImg from '../assets/images/diagrama.png'; 

const PropuestaValor = () => {
  return (
    <section className="propuesta-valor">
      <h2 className="propuesta-title">PROPUESTA DE VALOR</h2>
      <h3 className="sub-title">ARTE CON IMPACTO</h3>
      <p className="description">Somos el puente entre los Artistas Independientes y su público</p>
      
      <div className="content-container">
        {/* Primera columna */}
        <div className="first-column">
          <div className="image-row">
            <img src={artistaImg} alt="Artista con pincel" className="image-half" />
            <img src={publicoImg} alt="Artista con notebook" className="image-half" /> 
          </div>
        </div>

        {/* Segunda columna */}
        <div className="second-column">
          <img src={obraImg} alt="Obra" className="obra-img" />
        </div>
      </div>

      {/* Sección de texto y diagrama */}
      <div className="text-diagram-section">
        <div className="additional-text">
          <p>
            Para abordar este desafío, hemos creado <strong>Viewseum</strong>, una plataforma digital que conecta a 
            <strong> artistas emergentes</strong> con galerías, museos y coleccionistas de todo el mundo. Nuestra 
            propuesta de valor es clara: ofrecemos una solución que permite a los artistas <strong>ganar visibilidad </strong> 
            y <strong>vender sus obras de manera directa</strong>, mientras que educamos al público sobre la accesibilidad y 
            el valor del <strong>arte original</strong>.
          </p>
          <p>
            Además, queremos aprovechar un entorno híbrido que excede a lo virtual, generando 
            <strong> exposiciones presenciales</strong> gracias a alianzas con eventos y campañas de impacto de otros 
            sectores, como los <strong>vinos sostenibles</strong> (Eventos <em>Wine and Art</em>), eventos educativos, 
            alianzas con instituciones de impacto, etc. Generamos una relación entre arte e impacto social desde 
            distintos entornos, permitiendo la visibilización y experiencia tanto <strong>virtual</strong> como 
            <strong> presencial</strong> de las obras.
          </p>
        </div>
        <div className="diagram-container">
          <img src={diagramaImg} alt="Diagrama" className="diagram-img" />
        </div>
      </div>
    </section>
  );
};

export default PropuestaValor;
