import React from 'react';
import './Header.css'; // Importamos los estilos personalizados
import { Link } from 'react-scroll'; // Importamos Link de react-scroll

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="home" smooth={true} duration={1000} className="logo-link">
          VIEWSEUM
        </Link>
      </div>
      <nav className="nav-menu">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="about" smooth={true} duration={1000} className="nav-link">
              NOSOTROS
            </Link>
          </li>
          <li className="nav-item">
            <Link to="solution" smooth={true} duration={1000} className="nav-link">
              PRODUCTO
            </Link>
          </li>
          <li className="nav-item">
            <Link to="contact" smooth={true} duration={1000} className="nav-link">
              CONTACTO
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
