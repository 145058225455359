import React from "react";
import Hero from "./components/Hero";
import Solution from './components/Solution';
import Contact from './components/Contact';
import Problema from './components/Problem';
import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import PropuestaValor from "./components/PropuestaValor";
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <AboutUs />
      <Problema />
      <Solution />
      <PropuestaValor />
      <Contact />

    </div>
  );
}

export default App;
